<template>
  <div class="main">
    <div class="box">
      <div class="boxTitle">{{ $fanyi('提现申请信息') }}</div>
      <div class="userBalanceContainer">
        <div>
          {{ $fanyi('当前账户余额') }}：<span>${{ userInfo.balance_usable }}</span>
        </div>
      </div>

      <!-- 表单区域 -->
      <div class="formregion">
        <div class="rechargeBasicInformation">{{ $fanyi('基本信息') }}</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <!-- 账户名称 -->
          <el-form-item :label="$fanyi('账户名称') + ':'" prop="userName">
            <el-input v-model="ruleForm.userName"></el-input>
          </el-form-item>
          <!-- 银行账户 -->
          <el-form-item :label="$fanyi('银行账户') + ':'" prop="bankAccount">
            <el-input v-model="ruleForm.bankAccount"></el-input>
          </el-form-item>
          <!-- 提现金额 -->
          <el-form-item :label="$fanyi('提现金额') + ':'" prop="withdrawalPrice">
            <el-input type="number" v-model="ruleForm.withdrawalPrice"></el-input>
          </el-form-item>
          <!-- 提现申请原因 -->
          <el-form-item :label="$fanyi('提现申请原因') + ':'" prop="bankRemark">
            <el-input v-model="ruleForm.bankRemark"></el-input>
          </el-form-item>


        </el-form>
        <div class="btnContainer">
          <div @click="showWithdrawalConfirmDialogVisible">{{ $fanyi('确认提交') }}</div>
        </div>
      </div>
    </div>
    <!-- 提现弹层 -->
    <el-dialog :visible.sync="dialogVisible" width="400px" :show-close="false">
      <div class="flexAndCenter couponHeader">
        <div>{{ $fanyi('信息确认') }}</div>
        <i class="el-icon-close" @click="dialogVisible = false"></i>
      </div>
      <div class="couponBody">
        <div class="withdrawalSubmitText">
          {{ $fanyi('请您核对以下信息是否正确') }}
        </div>
        <div class="flexAndCenter withdrawalMessage">
          <div class="leftText">{{ $fanyi('提现金额') }}：</div>
          <div class="flexAndCenter withdrawalPrice">
            <div>{{ $fun.EURNumSegmentation(ruleForm.withdrawalPrice) }}</div>
            <div>{{ $fanyi('美元') }}</div>
          </div>
        </div>
        <div class="flexAndCenter withdrawalMessage">
          <div class="leftText">{{ $fanyi('账户名称') }}：</div>
          <div class="rightText">{{ ruleForm.userName }}</div>
        </div>
        <div class="flexAndCenter withdrawalMessage" style="margin-bottom: 0px">
          <div class="leftText">{{ $fanyi('银行账户') }}：</div>
          <div class="rightText">{{ ruleForm.bankAccount }}</div>
        </div>
      </div>
      <div class="btnList flexAndCenter">
        <div @click="Withdraw">{{ $fanyi('确认') }}</div>

        <div @click="dialogVisible = false">{{ $fanyi('取消') }}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    // 字段校验
    var updateWithdrawalPrice = (rule, value, callback) => {
      console.log(this.userInfo.balance);
      if (Number(value) > this.userInfo.balance) {
        callback(new Error(this.$fanyi('提现金额不可大于当前账户余额')))
        return
      }
      if (Number(value) < 0) {
        callback(new Error(this.$fanyi('提现金额不能小于0')))
        return
      }
      callback()

    }
    return {
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('付款'),
        txtcenter: true,
        btnTxt: [this.$fanyi('提交'), this.$fanyi('取消')],
      },
      rules: {
        userName: [
          { required: true, message: this.$fanyi('请输入账户名称'), trigger: 'blur' },


        ],
        bankAccount: [
          { required: true, message: this.$fanyi('请输入银行账户'), trigger: 'blur' },
        ],
        withdrawalPrice: [
          { required: true, message: this.$fanyi('请输入提现金额'), trigger: 'blur' }, {
            validator: updateWithdrawalPrice,
            trigger: 'blur',
          }
        ],
        bankRemark: [
          { required: true, message: this.$fanyi('提现申请原因'), trigger: 'blur' },
        ],

      },
      ruleForm: {
        userName: '',// 账户名称
        bankAccount: '',// 银行账户
        withdrawalPrice: '',//提现金额
        bankRemark: '',//提现原因
      },
      dialogVisible: false,

      userInfo: {},
      timer: '',

    }
  },

  computed: {},
  created() {
    this.EuropegetUserInfo()
  },
  methods: {
    // 提现
    Withdraw() {
      this.dialogVisible = false
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.$api.capitalCashOutSend({
            client_name: this.ruleForm.userName,
            client_account: this.ruleForm.bankAccount,
            client_amount: this.ruleForm.withdrawalPrice,
            client_reason: this.ruleForm.bankRemark,
          }).then((res) => {
            if (res.code !== 0) return this.$message.error(res.msg)

            this.$fun.toPage('/user/paymentsummary')
            this.$message({
              message: '提现成功，待财务审核',
              type: 'success',
            })
            location.reload()
          })
          this.timer = null

        }, 1000)

      }

    },
    // 获取用户信息
    EuropegetUserInfo() {
      this.$api.EuropegetUserInfo().then((res) => {
        this.$store.commit('userData', res.data)
        this.userInfo = res.data
      })
    },


    //提交提现申请
    async showWithdrawalConfirmDialogVisible() {

      try {
        await this.$refs.ruleForm.validate()
        this.dialogVisible = true

      } catch (e) {

      }

    },

  },
}
</script>
<style lang="scss" scoped>
@import '../../../../css/mixin.scss';

.main {
  width: 1180px;
  height: 620px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  overflow: hidden;

  /deep/ .el-dialog__header {
    display: none;

  }

  /deep/.el-dialog__body {
    padding: 0 !important;
  }

  /deep/ .el-dialog__header,
  /deep/ .el-dialog__body,
  /deep/.el-dialog__footer {
    padding: 0;
  }

  .box {
    background: #ffffff;

    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .boxTitle {
      width: 1180px;
      height: 60px;
      background: #f2f2f2;

      display: flex;
      align-items: center;
      padding: 30px;
      font-weight: 500;
      font-size: 18px;
    }

    .userBalanceContainer {
      width: 1100px;
      height: 88px;
      background: #f6f6f6;
      border-radius: 6px;
      margin: 30px 30px 30px 40px;
      padding-left: 30px;
      background: #f9f9f9;
      display: flex;

      align-items: center !important;

      div {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        display: flex;

        align-items: center;

        span {
          font-size: 32px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
          color: #ff730b;
        }
      }
    }

    .formregion {
      padding: 0 60px 40px;

      .rechargeBasicInformation {
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 30px;


      }

      /deep/.el-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      /deep/.el-form-item {
        margin-bottom: 30px;
        width: 480px;


        .el-form-item__label {
          line-height: 20px;
          margin-bottom: 10px;
          font-size: 14px;
        }

        .el-form-item__error {
          margin-top: 5px;
        }

        .el-input__inner {
          height: 48px;
        }


      }

      .btnContainer {
        width: 100%;
        height: 48px;


        margin-top: 20px;

        >div {
          width: 160px;
          height: 48px;
          background: #ff730b;
          border-radius: 4px !important;
          margin: auto;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
          color: #fff;
        }
      }
    }

  }


  .couponHeader {
    justify-content: space-between;
    //padding:0 10px;
    border-bottom: 1px solid #f5f5f5;
    height: 64px;
    margin: 0 10px;

    div:first-child {
      color: #222222;
      font-size: 18px;
      padding-left: 20px;
    }

    .el-icon-close {
      color: #000;
      font-size: 28px;
      font-weight: bold;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .couponBody {
    padding: 30px 40px;

    .withdrawalSubmitText {
      margin-bottom: 24px;
      color: #999999;
      font-size: 14px;
    }

    .withdrawalMessage {
      margin-bottom: 20px;

      .leftText {
        color: #222222;
        font-size: 14px;
        min-width: 100px;
        text-align: right;
      }

      .withdrawalPrice {
        div:first-child {
          color: #ff730b;
          font-weight: 600;
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  .btnList {
    padding: 0 60px 30px;

    div {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 4px;
      color: #fff;
      background: #ff730b;
      cursor: pointer;
      border: 1px solid #ff730b;
    }

    div:first-child {
      margin-right: 40px;
    }

    div:last-child {
      color: #222222;
      background: #fff;
      border: 1px solid #e2e2e2;
    }
  }
}

.el-range-separator {
  width: 50px;
}
</style>
